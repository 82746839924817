import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  TablePagination,
  Button,
  Skeleton,
  TableFooter
} from '@mui/material'
import { navigate } from 'gatsby'
import { Home, Settings, ShoppingBag } from '@mui/icons-material'

import { routePaths } from '../../../constants/routes'
import { formatStatus } from '../../../helpers'
import { Order, OrderStatus } from '../../../helpers/types'
import OrdersFilterMenu from '../../OrdersFilterMenu'
import { useProfileContext } from '../../../contexts/profileContext'
import { SubContainer } from '../../common/Styled'
import Header from '../../common/Header'
import { fetchAdminOrders } from '../../../helpers/api/order'

const title = 'Commandes'
const items = [
  {
    icon: <Home />,
    label: 'Accueil',
    route: routePaths.home
  },
  {
    icon: <Settings />,
    label: 'Admin',
    route: routePaths.admin.index
  },
  {
    icon: <ShoppingBag />,
    label: title
  }
]

const Orders = () => {
  const [loading, setLoading] = useState(true)
  const profile = useProfileContext()
  const [filters, setFilters] = useState<OrderStatus[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [orders, setOrders] = useState<Order[]>([])
  const isAdmin = profile?.isAdmin

  useEffect(() => {
    const fetchOrders = async () => {
      const result = await fetchAdminOrders(filters)
      setOrders(result)
      setLoading(false)
    }
    if (isAdmin) fetchOrders()
  }, [isAdmin, filters])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeFilters = (value: OrderStatus) => () => {
    const currentIndex = filters.indexOf(value)
    const newFilters = [...filters]

    if (currentIndex === -1) {
      newFilters.push(value)
    } else {
      newFilters.splice(currentIndex, 1)
    }

    setFilters(newFilters)
  }

  const resetFilter = () => setFilters([])

  if (!isAdmin) return null

  return (
    <SubContainer>
      <Header
        {...{ items, title }}
        filter={
          <OrdersFilterMenu
            {...{ handleChangeFilters, filters, resetFilter }}
          />
        }
      />
      <Paper>
        <TableContainer style={{ marginTop: 20, maxHeight: '70vh' }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Utilisateur</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Nombre de produits</TableCell>
                <TableCell>Date de commande</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading &&
                new Array(3).fill('').map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton height="45px" />
                    </TableCell>
                  </TableRow>
                ))}
              {!loading &&
                orders.length > 0 &&
                orders
                  .sort(
                    (a, b) =>
                      (new Date(b.orderDate) as any) -
                      (new Date(a.orderDate) as any)
                  )
                  .map(
                    ({
                      id,
                      user: { firstName, lastName },
                      details,
                      orderDate,
                      status
                    }) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          {id}
                        </TableCell>
                        <TableCell>{`${firstName} ${lastName}`}</TableCell>
                        <TableCell>{formatStatus(status)}</TableCell>
                        <TableCell>{details?.products?.length}</TableCell>
                        <TableCell>
                          {moment.utc(orderDate).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              navigate(`${routePaths.admin.order}?id=${id}`)
                            }
                          >
                            Voir la commande
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              {!loading && orders.length === 0 && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    colSpan={6}
                  >
                    Il n&#39;y a aucune commande
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                count={orders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </SubContainer>
  )
}

export default Orders
