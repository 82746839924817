import React from 'react'

import {
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Menu,
  IconButton,
  Tooltip,
  Stack,
  Badge
} from '@mui/material'
import { OrderStatus } from '../../helpers/types'
import { formatStatus } from '../../helpers'
import { FilterAltOff, FilterList } from '@mui/icons-material'

interface FilterMenuProps {
  filters: OrderStatus[]
  handleChangeFilters: (value: OrderStatus) => () => void
  resetFilter: () => void
}

const options = [
  {
    value: OrderStatus.PAYMENT_ACCEPTED,
    label: formatStatus(OrderStatus.PAYMENT_ACCEPTED)
  },
  {
    value: OrderStatus.PROCESSING,
    label: formatStatus(OrderStatus.PROCESSING)
  },
  {
    value: OrderStatus.SHIPPING_PROCESS,
    label: formatStatus(OrderStatus.SHIPPING_PROCESS)
  },
  {
    value: OrderStatus.DELIVERED,
    label: formatStatus(OrderStatus.DELIVERED)
  },
  {
    value: OrderStatus.READY_TO_COLLECT,
    label: formatStatus(OrderStatus.READY_TO_COLLECT)
  },
  {
    value: OrderStatus.COLLECTED,
    label: formatStatus(OrderStatus.COLLECTED)
  }
]

const FilterMenu = (props: FilterMenuProps) => {
  const { filters, handleChangeFilters, resetFilter } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack direction="row" gap={1}>
      {filters.length > 0 && (
        <Tooltip arrow title="Réinitialiser les filtres">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={resetFilter}
            color="secondary"
          >
            <FilterAltOff />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip arrow title="Filtres">
        <Badge badgeContent={filters.length} color="primary">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color="secondary"
          >
            <FilterList />
          </IconButton>
        </Badge>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {options.map(({ value, label }) => (
          <ListItem
            key={value}
            role={undefined}
            dense
            button
            onClick={handleChangeFilters(value)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={filters.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                color="primary"
              />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </Menu>
    </Stack>
  )
}

export default FilterMenu
