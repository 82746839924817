import React from 'react'
import OrdersView from '../../components/Admin/Orders'
import SEO from '../../components/common/seo'

const Orders = () => (
  <>
    <SEO title="Commandes" />
    <OrdersView />
  </>
)

export default Orders
